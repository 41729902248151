import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Router>
          <Routes>
              <Route path="/" element={<App />} >
                  <Route path=":weekId" component={<App />} />
              </Route>
              {/* TODO: Optional Path Params were only reintroduced in v6.5, currently on v6.23 */}
          </Routes>
      </Router>
  </React.StrictMode>
);
