import {useEffect, useState} from 'react';
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    TextField,
    Tab,
    Tabs, FormControl, InputLabel, Select, MenuItem, TablePagination, Autocomplete, Checkbox
} from '@mui/material';
import axios from "axios";
import './App.css';
import CountdownTimer from "./components/CountdownTimer/CountdownTimer";
import { useParams, useNavigate } from "react-router-dom";

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const defaultLeaderboardDataStorageObj = {
    week1 : {
        lastUpdated : 0,
        leaderboardData : {}
    }
}

const defaultLeaderboardData = {
    "Endless": [],
    "BossRush": [],
    "Arena": [],
    "Solo": [],
    "Guild": []
};

const defaultEventDetails = {
    eventName: "",
    eventStartDatetime: 0, // start date in seconds
    eventEndDatetime: 0, // end date in seconds
};

const defaultGuildList = [
    { guildName: "All Guilds" },
];

const leaderboardDataUrl = "https://pruq7kg1o4.execute-api.us-east-2.amazonaws.com/api/v0/gog/data/weeklyleaderboard";
const leaderboardDataLastUpdatedUrl = "https://arhhiu1h96.execute-api.us-east-2.amazonaws.com/api/v0/gog/data/leaderboard/lastupdated";

function App() {
    const { weekId } = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    const [eventDetails, setEventDetails] = useState(defaultEventDetails);
    const [dataMap, setDataMap] = useState(defaultLeaderboardData);

    const [searchTerm, setSearchTerm] = useState('');

    const [guildList, setGuildList] = useState(defaultGuildList);
    const [selectedGuild, setSelectedGuild] = useState('all')
    const [selectedWeek, setSelectedWeek] = useState('week14');
    const [activeTab, setActiveTab] = useState('Endless');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const filteredData = dataMap[activeTab].filter(
        (player) => (activeTab === "Guild") ? (player.guildName.toString().includes(searchTerm)) : (player.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            player.userId.toString().includes(searchTerm) || ((player.guildName) ? player.guildName.toString().includes(searchTerm) : false ))
    );

    const handleGuildChange = (event) => {
        setDataMap(defaultLeaderboardData);
        setSelectedGuild(event.target.value);
    };

    const handleWeekChange = (event) => {
        setDataMap(defaultLeaderboardData);
        setSelectedWeek(event.target.value);
    };

    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 25));
        setPage(0);
    };

    const handleSearchChange = (event) => {
        // TODO: This is the cause of the pagination weirdness bug
        handleChangePage(null, 0);
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        setIsLoading(true);

        let decompressJsonObj = (compressedLeaderboardData) => {
            let reformattedLeaderboardData = {
                "Endless": [],
                "BossRush": [],
                "Arena": []
            }

            Object.keys(compressedLeaderboardData).forEach((userId) => {
                const user = compressedLeaderboardData[userId];
                if (user.e.r) {
                    reformattedLeaderboardData.Endless.push({
                        userId: user.id,
                        userName: user.n,
                        guildName: user.gn,
                        rank: user.e.r,
                        score: user.e.s,
                        rewardTokens: user.e.t
                    });
                }
                if (user.b.r) {
                    reformattedLeaderboardData.BossRush.push({
                        userId: user.id,
                        userName: user.n,
                        guildName: user.gn,
                        rank: user.b.r,
                        score: user.b.s,
                        rewardTokens: user.b.t
                    });
                }
                if (user.a.r) {
                    reformattedLeaderboardData.Arena.push({
                        userId: user.id,
                        userName: user.n,
                        guildName: user.gn,
                        rank: user.a.r,
                        score: user.a.s,
                        rewardTokens: user.a.t
                    });
                }
            });

            reformattedLeaderboardData.Endless = reformattedLeaderboardData.Endless.sort((a, b) => a.rank - b.rank);
            reformattedLeaderboardData.BossRush = reformattedLeaderboardData.BossRush.sort((a, b) => a.rank - b.rank);
            reformattedLeaderboardData.Arena = reformattedLeaderboardData.Arena.sort((a, b) => a.rank - b.rank);

            return reformattedLeaderboardData;
        }

        const updateStorageLeaderboardEventsObj = (targetWeek, updatedEventObj) => {
            let eventObj = localStorage.getItem(targetWeek.toString());
            eventObj = JSON.parse(eventObj);

            if (eventObj === null) {
                eventObj = {};
            }

            eventObj = updatedEventObj;

            localStorage.setItem(targetWeek.toString(), JSON.stringify(eventObj));
        }

        const checkForFreshData = async () => {
            const targetWeek = (weekId) ? weekId : selectedWeek;
            let leaderboardEventObj = JSON.parse(localStorage.getItem(targetWeek.toString()));

            if (leaderboardEventObj === null) {
                leaderboardEventObj = {
                   lastUpdated : 0,
                   eventDetails : {},
                   leaderboardData : {}
               };
            }

            // Fetch Last Updated Config
            axios.get(leaderboardDataLastUpdatedUrl + `?weekId=${targetWeek}`)
                .then(response => {
                    let isStorageDataFresh = false;
                    isStorageDataFresh = (leaderboardEventObj.lastUpdated >= response.data.lastUpdated);

                    if (!isStorageDataFresh) {
                        axios.get(leaderboardDataUrl + `?weekId=${(weekId) ? weekId : selectedWeek}`)
                            .then(response => {
                                setEventDetails(response.data.eventDetails);

                                let leaderboardData = response.data.leaderboardData;
                                if (["Week 4", "Week 6", "Week 7", "Week 6 and 7", "Week 8", "Week 9", "Week 10", "Week 11", "Week 12", "Season 3", "Week 13", "Week 14"].includes(response.data.eventDetails.eventName)) {
                                    leaderboardData = decompressJsonObj(leaderboardData);
                                }

                                if (["E1: Building Blocks"].includes(response.data.eventDetails.eventName)) {
                                    setActiveTab("Solo");
                                } else {
                                    setActiveTab("Endless");
                                }

                                // let newEventObj = {
                                //     lastUpdated : Date.now(),
                                //     eventDetails : response.data.eventDetails,
                                //     leaderboardData : response.data.leaderboardData
                                // }
                                //updateStorageLeaderboardEventsObj(targetWeek, newEventObj);
                                setDataMap(leaderboardData);
                                setIsLoading(false);
                                navigate('/');
                            })
                            .catch(error => {
                                console.error('Error fetching data:', error);
                            });
                    } else {
                        setEventDetails(leaderboardEventObj.eventDetails);

                        let leaderboardData = leaderboardEventObj.leaderboardData;
                        if (["Week 4", "Week 6", "Week 7", "Week 6 and 7", "Week 8", "Week 9", "Week 10", "Week 11", "Week 12", "Season 3", "Week 13", "Week 14"].includes(leaderboardEventObj.eventDetails.eventName)) {
                            leaderboardData = decompressJsonObj(leaderboardData);
                        }

                        setDataMap(leaderboardData);
                        setIsLoading(false);
                        navigate('/');
                    }

                });
        };

        checkForFreshData();

    }, [selectedWeek]);

    return (
        <div className={"backgroundContainer"} style={{background: "url('/imperial-image.png') no-repeat top center / 100%"}}>
            <div className={"content"}>
                <img className={'logoImg'} src={'/gog-logo-white.svg'}/>
                <Container maxWidth="md" className={"heroTextContainer"}>
                    <Typography variant="h2" component="h2" className={`heroText`}>
                        {eventDetails.eventName}
                    </Typography>
                    <Typography variant="h6" component="h6" className={`heroText`}>
                        {new Date(eventDetails.eventStartDatetime * 1000).toUTCString()} to {new Date(eventDetails.eventEndDatetime * 1000).toUTCString()}
                    </Typography>
                    <CountdownTimer endTime={eventDetails.eventEndDatetime}/>
                </Container>
                <Container maxWidth="md" sx={{ marginTop: '20px'}}>
                    <TextField
                        fullWidth
                        label="Search by Name or ID"
                        variant="filled"
                        className="searchBar"
                        onChange={handleSearchChange}
                        size="small"
                    />
                </Container>
                <Container maxWidth="md" className="tableContainer">
                    <Typography variant='body1' sx={{textAlign: 'left', margin: '10px 0px 40px'}} >
                        Numbers based on official data but may not always be accurate, the GOG Team reserves the right to make adjustments as they see fit.
                    </Typography>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: "white"}}>
                        { (selectedWeek === "event1") ? (
                            <Tabs value={activeTab} onChange={handleChangeTab} className="tabContainer">
                                <Tab label="Solo" value="Solo"/>
                                <Tab label="Guild" value="Guild"/>
                            </Tabs>
                        ) : (
                            <Tabs value={activeTab} onChange={handleChangeTab} className="tabContainer">
                                <Tab label="Endless" value="Endless"/>
                                <Tab label="Boss Rush" value="BossRush"/>
                                <Tab label="Arena" value="Arena"/>
                            </Tabs>
                        )}
                        {/*display: "flex", flexDirection: "row", justifyContent: "space-between",*/}
                        <FormControl variant="standard" style={{ marginRight: 10, minWidth: 240 }} size="small">
                            {/*<InputLabel id="guild-filter-select-label">Guild</InputLabel>*/}
                            {/*<Select*/}
                            {/*    labelId="guild-filter-select-label"*/}
                            {/*    id="guild-filter-select"*/}
                            {/*    value={selectedGuild}*/}
                            {/*    onChange={handleGuildChange}*/}
                            {/*    style={{minWidth : 140}}*/}

                            {/*>*/}
                            {/*    <MenuItem value="all">All Guilds</MenuItem>*/}
                            {/*</Select>*/}
                            {/*<Autocomplete*/}
                            {/*    multiple*/}
                            {/*    id="checkboxes-tags-demo"*/}
                            {/*    options={guildList}*/}
                            {/*    disableCloseOnSelect*/}
                            {/*    getOptionLabel={(option) => option.guildName}*/}
                            {/*    renderOption={(props, option, { selected }) => {*/}
                            {/*        const { key, ...optionProps } = props;*/}
                            {/*        return (*/}
                            {/*            <li key={key} {...optionProps}>*/}
                            {/*                <Checkbox*/}
                            {/*                    icon={icon}*/}
                            {/*                    checkedIcon={checkedIcon}*/}
                            {/*                    style={{ marginRight: 8 }}*/}
                            {/*                    checked={selected}*/}
                            {/*                />*/}
                            {/*                {option.guildName}*/}
                            {/*            </li>*/}
                            {/*        );*/}
                            {/*    }}*/}
                            {/*    style={{ minWidth: 140 }}*/}
                            {/*    renderInput={(params) => (*/}
                            {/*        <TextField {...params} label="Guilds" placeholder="Guilds" />*/}
                            {/*    )}*/}
                            {/*/>*/}

                            <Select
                                value={selectedWeek}
                                onChange={handleWeekChange}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="week14">Week 14</MenuItem>
                                <MenuItem value="week13">Week 13</MenuItem>
                                <MenuItem value="season3">Season 3</MenuItem>
                                <MenuItem value="week12">Week 12</MenuItem>
                                <MenuItem value="week11">Week 11</MenuItem>
                                <MenuItem value="event1">Building Blocks</MenuItem>
                                <MenuItem value="week10">Week 10</MenuItem>
                                <MenuItem value="week9">Week 9</MenuItem>
                                <MenuItem value="week8">Week 8</MenuItem>
                                <MenuItem value="week6and7">Week 6 and 7</MenuItem>
                                <MenuItem value="week7">Week 7</MenuItem>
                                <MenuItem value="week6">Week 6</MenuItem>
                                <MenuItem value="week4">Week 4</MenuItem>
                                <MenuItem value="week3">Week 3</MenuItem>
                                <MenuItem value="week2">Week 2</MenuItem>
                                <MenuItem value="week1">Week 1</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <TableContainer component={Paper} sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                        <Table aria-label="simple table">
                            {filteredData.length > 0 ? (
                                <TableHead>
                                    { (selectedWeek === "event1") ? (
                                            (activeTab === "Solo")? (
                                                <TableRow>
                                                    <TableCell>Rank</TableCell>
                                                    <TableCell>Guild</TableCell>
                                                    <TableCell>Username</TableCell>
                                                    <TableCell>User ID</TableCell>
                                                    <TableCell>Score</TableCell>
                                                </TableRow>
                                            ) : (
                                                <TableRow>
                                                    <TableCell>Rank</TableCell>
                                                    <TableCell>Guild</TableCell>
                                                    <TableCell>Sum Score</TableCell>
                                                    <TableCell>Eligible Members</TableCell>
                                                </TableRow>
                                            )
                                    ) : (
                                        <TableRow>
                                            <TableCell>Rank</TableCell>
                                            {(["week6", "week7", "week6and7", "week8", "week9", "week10", "week11", "week12", "season3", 'week13', 'week14'].includes(selectedWeek)) ? (
                                                <TableCell>Guild</TableCell>
                                            ) : ""}
                                            <TableCell>Username</TableCell>
                                            <TableCell>User ID</TableCell>
                                            <TableCell>Score</TableCell>
                                            { (["week3", "week4", "week6", "week7", "week6and7"].includes(selectedWeek)) ? (
                                                <TableCell>Reward: $GOG Tokens</TableCell>
                                            ) : ( (["week8", "week9", "week10", "week11", "week12", "season3", 'week13', 'week14'].includes(selectedWeek)) ? ("") : (
                                                    <TableCell>Reward: Ascension Seals</TableCell>
                                                )
                                            )}
                                        </TableRow>
                                    )}
                                </TableHead>
                            ) : ""}
                            <TableBody>
                                {isLoading ? (
                                    <TableRow>
                                        <TableCell colSpan={4} className="userNotFoundCell">
                                            Loading data, please wait...
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    filteredData.length > 0 ? (
                                        (selectedWeek === "event1") ? (
                                            (activeTab === "Solo") ? (
                                                filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                                    <TableRow key={row.id}>
                                                        <TableCell component="th" scope="row">
                                                            {row.rank.toLocaleString()}
                                                        </TableCell>
                                                        <TableCell>{row.guildName}</TableCell>
                                                        <TableCell>{row.userName}</TableCell>
                                                        <TableCell>{row.userId}</TableCell>
                                                        <TableCell>{row.score.toLocaleString()}</TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                                    <TableRow key={row.id}>
                                                        <TableCell component="th" scope="row">
                                                            {row.guildRank.toLocaleString()}
                                                        </TableCell>
                                                        <TableCell>{row.guildName}</TableCell>
                                                        <TableCell>{row.sumScore.toLocaleString()}</TableCell>
                                                        <TableCell>{row.eligMembers}</TableCell>
                                                    </TableRow>
                                                ))
                                            )
                                        ) : (
                                            filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                                <TableRow key={row.id}>
                                                    <TableCell component="th" scope="row">
                                                        {row.rank.toLocaleString()}
                                                    </TableCell>
                                                    {(["week6", "week7", "week6and7", "week8", "event1", "week9", "week10", "week11", "week12", "season3", 'week13', 'week14'].includes(selectedWeek)) ? (
                                                        <TableCell>{row.guildName}</TableCell>
                                                    ) : ""}
                                                    <TableCell>{row.userName}</TableCell>
                                                    <TableCell>{row.userId}</TableCell>
                                                    <TableCell>{row.score.toLocaleString()}</TableCell>
                                                    {(["week3", "week4", "week6", "week7", "week6and7"].includes(selectedWeek)) ? (
                                                        <TableCell align="center">{(row.rewardTokens !== "TBA") ? row.rewardTokens.toFixed(2).toLocaleString() : row.rewardTokens}</TableCell>
                                                    ) : ( (["week8", "week9", "week10", "week11", "week12", "season3", 'week13', 'week14'].includes(selectedWeek)) ? ("") : (
                                                            <TableCell align="center">{row.rewardAscensionSeal.toLocaleString()}</TableCell>
                                                        )
                                                    )}
                                                </TableRow>
                                            ))
                                        )
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={4} className="userNotFoundCell">
                                                User Not Found
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Container>
            </div>
        </div>
    );
}

export default App;
