// Created by Karoly Somogyvari, 5/18/2024
import { useState, useEffect } from "react";
import './CountdownTimer.css';

const CountdownTimer = ({endTime}) => {

    const calculateTimeLeft = () => {
        const now = Math.floor(Date.now() / 1000);
        const distance = endTime - now;

        const days = Math.floor(distance / (3600 * 24));
        const hours = Math.floor((distance % (3600 * 24)) / 3600);
        const minutes = Math.floor((distance % 3600) / 60);
        const seconds = distance % 60;

        return { days, hours, minutes, seconds, distance };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            const updatedTimeLeft = calculateTimeLeft();
            setTimeLeft(updatedTimeLeft);

            if (updatedTimeLeft.distance <= 0) {
                clearInterval(timer);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [endTime]);

    return (
        <div className={"timerDisplay"}>
            <h3>End of Week Countdown: </h3>
            <div>
                {timeLeft.distance > 0 ? (
                    <h3>
                        {String(timeLeft.days).padStart(1, '0')} Days&nbsp;
                        {String(timeLeft.hours).padStart(2, '0')} Hours&nbsp;
                        {String(timeLeft.minutes).padStart(2, '0')} Mins&nbsp;
                        {String(timeLeft.seconds).padStart(2, '0')} Secs
                    </h3>
                ) : (
                    <span>The Week has ended.</span>
                )}
            </div>
        </div>
    );
}

export default CountdownTimer;